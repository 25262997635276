import Icon from '@/components/icon'
import { link } from '@/helper/link'
import { t } from '@lingui/macro'
import UserPopUp from '@/features/user/components/popup'
import UserPopupTipsContent from '@/features/user/components/popup/content/tips'
import { useUserStore } from '@/store/user'
import styles from './index.module.css'

interface securityPopup {
  /** 弹框显示 */
  isShow?: boolean
  /** 关闭弹框 */
  setIsShow(val): void
  /** 关闭其他的弹窗 */
  setOtherClose?: () => void
}

function OpenSecurityPopup(props: securityPopup) {
  const { isShow, setIsShow, setOtherClose } = props
  const { merchantSettings } = useUserStore()
  /** 去开启两项验证 */
  const onOpenSecurity = () => {
    isShow && setIsShow(false)
    link('/personal-center/account-security')
    setOtherClose && setOtherClose()
  }
  /** 关闭弹框 */
  const handleClose = () => {
    isShow && setIsShow(false)
  }

  return (
    <UserPopUp
      className="user-popup user-popup-tips popup-width"
      visible={isShow}
      closeIcon={<Icon name="close" hasTheme />}
      okText={t`user.universal_security_verification_08`}
      cancelText={t`user.field.reuse_44`}
      onOk={onOpenSecurity}
      onCancel={handleClose}
    >
      <UserPopupTipsContent
        showIcon={false}
        className={styles.scoped}
        slotContent={
          <>
            <p className="popup-title">{t`features/c2c-trade/advertisement-manager/index-11`}</p>
            <p className="popup-small-title">{t`user.universal_security_verification_07`}</p>
            {merchantSettings.isEmailEnable && (
              <p className="popup-small-title">{t`user.universal_security_verification_09`}</p>
            )}
            {merchantSettings.isMobileEnable && (
              <p className="popup-small-title">{t`user.universal_security_verification_10`}</p>
            )}
            {merchantSettings.isGoogleEnable && (
              <p className="popup-small-title">{t`user.universal_security_verification_11`}</p>
            )}
            {merchantSettings.isWithdrawPwdEnable && (
              <p className="popup-small-title">{t`features_user_withdraw_universal_security_verification_index_njwbtnedvf`}</p>
            )}
          </>
        }
      />
    </UserPopUp>
  )
}

export default OpenSecurityPopup
